/* eslint-disable max-lines */
import PropTypes from 'prop-types';
import React from 'react';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import Image from 'Component/Image';
import PRODUCT_TYPE from 'Component/Product/Product.config';
import ProductChips from 'Component/ProductChips';
import ProductTags from 'Component/ProductTags';
import TrainingCard from 'Component/TrainingCard';
import {
    ProductCard as SourceProductCard
} from 'SourceComponent/ProductCard/ProductCard.component';
import { getProductTags, isRestrictedToCurrentUser } from 'Util/Product/Product';

import './ProductCard.override.style';

/** @namespace PeggysagePwa/Component/ProductCard/Component */
export class ProductCardComponent extends SourceProductCard {
    static propTypes = {
        ...SourceProductCard.propTypes,
        otherThumbnails: PropTypes.arrayOf(PropTypes.string),
        trainingAttributeSetId: PropTypes.number.isRequired,
        isPictureSliderEnabled: PropTypes.bool,
        imageSecondaire: PropTypes.arrayOf(PropTypes.string),
        isSkuVisible: PropTypes.bool.isRequired,
        isProEtu: PropTypes.bool.isRequired,
        isCustomerTypeStudentEnabled: PropTypes.bool.isRequired
    };

    static defaultProps = {
        ...SourceProductCard.defaultProps,
        isPictureSliderEnabled: false
    };

    /**
     * Oveerride: add verification of existence of options property
     * @returns {boolean|boolean}
     */
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    requiresConfiguration() {
        const {
            parameters,
            product: {
                type_id: type,
                options = [],
                items = [],
                links_purchased_separately
            }
        } = this.props;

        const configureBundle = type === PRODUCT_TYPE.bundle;

        const allAttrs = super.getConfigurableAttributes();
        const plpConfigurableAttrs = this.getConfigurableAttributes();

        const isConfigurable = type === PRODUCT_TYPE.configurable;

        const configureConfig = isConfigurable && (
            (
                Object.keys(allAttrs).length
                !== Object.keys(plpConfigurableAttrs).length
            )
            || (
                Object.values(plpConfigurableAttrs).some(
                    (value) => value.attribute_values.length === 0
                )
            )
            || (Object.keys(allAttrs).length > 0 && Object.keys(parameters).length === 0)
        );

        const configureGrouped = type === PRODUCT_TYPE.grouped
            && items.every(({ qty }) => qty === 0);

        const configureCustomize = options?.some(({ required = false }) => required);

        const configureDownloadableLinks = PRODUCT_TYPE.downloadable && links_purchased_separately === 1;

        return configureGrouped
            || configureBundle
            || configureConfig
            || configureCustomize
            || configureDownloadableLinks;
    }

    /**
     * Override: add other thumbnails (slider on mobile, hover on desktop)
     * @param mix
     * @returns {JSX.Element}
     */
    renderPicture(mix = {}) {
        const {
            product: { id, name },
            thumbnail,
            otherThumbnails,
            imageSecondaire,
            device,
            isPictureSliderEnabled
        } = this.props;

        if (device.isMobile && (otherThumbnails.length || imageSecondaire.length) && isPictureSliderEnabled) {
            this.sharedComponent = (
                <Swiper
                  modules={ [Pagination] }
                  pagination={ {
                      enabled: true
                  } }
                  className="ProductCard-Pictures"
                >
                    <SwiperSlide>
                        <Image
                          imageRef={ this.imageRef }
                          src={ thumbnail }
                          alt={ name }
                          ratio="custom"
                          mix={ { block: 'ProductCard', elem: 'Picture', mix } }
                          isPlaceholder={ !id }
                        />
                    </SwiperSlide>
                    { otherThumbnails.map((image, index) => (
                        <SwiperSlide
                          // eslint-disable-next-line react/no-array-index-key
                          key={ `slide-${index}` }
                        >
                            <Image
                              src={ image }
                              alt={ name }
                              ratio="custom"
                              mix={ { block: 'ProductCard', elem: 'Picture', mix } }
                              isPlaceholder={ !id }
                            />
                        </SwiperSlide>
                    )) }
                    { imageSecondaire.map((image, index) => (
                        <SwiperSlide
                          // eslint-disable-next-line react/no-array-index-key
                          key={ `slide-${index}` }
                        >
                            <Image
                              src={ image }
                              alt={ name }
                              ratio="custom"
                              mix={ { block: 'ProductCard', elem: 'Picture', mix } }
                              isPlaceholder={ !id }
                            />
                        </SwiperSlide>
                    )) }
                </Swiper>
            );
        } else {
            this.sharedComponent = (
                <div block="ProductCard" elem="Pictures">
                    <Image
                      imageRef={ this.imageRef }
                      src={ thumbnail }
                      alt={ name }
                      ratio="custom"
                      mix={ { block: 'ProductCard', elem: 'Picture', mix } }
                      isPlaceholder={ !id }
                    />
                    { isPictureSliderEnabled && otherThumbnails.map((image, index) => (
                        <Image
                          src={ image }
                          alt={ name }
                          ratio="custom"
                          mix={ { block: 'ProductCard', elem: 'Picture', mix } }
                          isPlaceholder={ !id }
                          // eslint-disable-next-line react/no-array-index-key
                          key={ `image-${index}` }
                        />
                    )) }
                    { isPictureSliderEnabled && imageSecondaire.map((image, index) => (
                        <Image
                          src={ image }
                          alt={ name }
                          ratio="custom"
                          mix={ { block: 'ProductCard', elem: 'Picture', mix } }
                          isPlaceholder={ !id }
                          // eslint-disable-next-line react/no-array-index-key
                          key={ `image-${index}` }
                        />
                    )) }
                </div>
            );
        }

        return (
            <>
                { this.sharedComponent }
                <span style={ { display: 'none' } } />
            </>
        );
    }

    renderProductTags() {
        const { product, isCustomerTypeStudentEnabled } = this.props;

        const tags = getProductTags(product, isCustomerTypeStudentEnabled);

        if (!tags.length) {
            return null;
        }

        return <ProductTags tags={ tags } />;
    }

    /**
     * Override: hide actions if product is not loaded, remove compare button, add tags
     * @returns {JSX.Element|null}
     */
    renderProductActions() {
        const { product: { name } } = this.props;

        if (!name) {
            return null;
        }

        return (
            <div block="ProductCard" elem="ProductActions">
                { this.renderProductTags() }
                { this.renderProductCardWishlistButton() }
            </div>
        );
    }

    renderContenance() {
        const { product: { contenance_label } } = this.props;

        if (!contenance_label) {
            return null;
        }

        return (
            <div block="ProductCard" elem="Contenance">
                { contenance_label }
            </div>
        );
    }

    renderSku() {
        const { product: { sku } } = this.props;

        return (
            <div block="ProductCard" elem="Sku">
                { __('SKU: %s', sku) }
            </div>
        );
    }

    renderSkuAndContenance() {
        const { isSkuVisible } = this.props;

        if (!isSkuVisible) {
            return null;
        }

        return (
            <div block="ProductCard" elem="SkuAndContenance">
                { this.renderContenance() }
                { this.renderSku() }
            </div>
        );
    }

    renderVariationsLink() {
        const { product: { variation_products_count } } = this.props;

        if (variation_products_count > 1) {
            return (
                <div block="ProductCard" elem="VariationsLink">
                    { __('%s variations', variation_products_count) }
                </div>
            );
        }

        return null;
    }

    /**
     * Override: do not render qty changer if the product has variations
     * @returns {null|*}
     */
    renderQuantityChanger() {
        const { product: { variation_products_count } } = this.props;

        if (variation_products_count > 1) {
            return null;
        }

        return super.renderQuantityChanger();
    }

    /**
     * Override: Hide button if product is in cart or if it has variations
     */
    renderAddToCart() {
        const { product, product: { variation_products_count }, isInCart } = this.props;

        if (isInCart || variation_products_count > 1) {
            return null;
        }

        if (isRestrictedToCurrentUser(product)) {
            return null;
        }

        return super.renderAddToCart();
    }

    renderProductChips() {
        const { product } = this.props;

        if (!product) {
            return null;
        }

        return <ProductChips product={ product } />;
    }

    /**
     * Override: change display
     * @returns {*}
     */
    renderCardContent() {
        const { renderContent, isProEtu, isSkuVisible } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return (
            this.renderCardLinkWrapper((
                <>
                    <div block="ProductCard" elem="FigureReview">
                        <figure block="ProductCard" elem="Figure">
                            { this.renderProductChips() }
                            { this.renderPicture() }
                        </figure>
                        { this.renderProductActions() }
                    </div>
                    <div block="ProductCard" elem="Content">
                        { this.renderBrand() }
                        { this.renderName(false) }
                        { !isSkuVisible && this.renderContenance() }
                        { this.renderReviews() }
                        <div block="ProductCard" elem="Footer" mods={ { isProEtu } }>
                            <div block="ProductCard" elem="FooterInner">
                                { this.renderSkuAndContenance() }
                                { this.renderConfigurableOptions() }
                                <div block="ProductCard" elem="FooterPriceWrapper">
                                    { this.renderPrice() }
                                    { this.renderVariationsLink() }
                                    { this.renderQuantityChanger() }
                                </div>
                            </div>
                            { this.renderAddToCart() }
                        </div>
                    </div>
                </>
            ))
        );
    }

    /**
     * Override: call other component for trainings
     * @returns {JSX.Element}
     */
    render() {
        const {
            mix,
            isLoading,
            product,
            product: {
                attribute_set_id: attributeSetId
            },
            trainingAttributeSetId
        } = this.props;

        if (trainingAttributeSetId && attributeSetId === trainingAttributeSetId) {
            return (
                <TrainingCard
                  product={ product }
                  mix={ mix }
                  isLoading={ isLoading }
                />
            );
        }

        return super.render();
    }
}

export default ProductCardComponent;
